<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Name" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Symbol" label-for="symbol">
                <validation-provider #default="{ errors }" name="symbol">
                  <b-form-input
                    v-model="form.symbol"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="symbol"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Coin Market Cap Id" label-for="cmcId">
                <validation-provider #default="{ errors }" name="cmcId">
                  <b-form-input
                    v-model="form.cmcId"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Coin Market Cap Id"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            Edit currency
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'
export default {
  name: 'RolesCreate',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      currencyId: null,
      form: {
        name: '',
        network: '',
        symbol: '',
        cmcId: '',
        image: '',
      },
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchCryptoCurrencyById: 'cryptoCurrency/fetchCryptoCurrencyById',
      updateCryptoCurrency: 'cryptoCurrency/updateCryptoCurrency',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return

      this.currencyId = this.$route.params.id
      this.getCurrencyById(this.currencyId)
    },

    update() {
      this.waitRequest(() => {
        return this.updateCryptoCurrency({ id: this.currencyId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Currency is updated',
                icon: 'PlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    getCurrencyById(currencyId) {
      this.fetchCryptoCurrencyById(currencyId)
        .then(response => {
          const { name, network, symbol, cmcId, image } = response.data
          this.form = {
            name,
            network,
            symbol,
            cmcId,
            image,
          }
        })
        .catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      return this.update()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
